@import 'colors';

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background-color: $background-color;
  font-family: 'Inter', sans-serif;
  color: $secondary-text-color;
}
